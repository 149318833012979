const animalfwiendata = [
    {
        name: "Bunni",
        image: 'https://i.imgur.com/ypbusxm.png',
        buttonText: 'Me! Click my button! Bunni\'s button, the bestest bunny around!',
        link: 'w4y66hXEJtA'
    },
    {
        name: "Snuffles",
        image: 'https://i.imgur.com/JR21WeF.png',
        alt: 'Sniff Sniff',
        buttonText: 'Hello, this is Snuffles Pink.',
        link: 'Ep6zKSSnUEs'
    },
    {
        name: "Mochi",
        image: 'https://i.imgur.com/9kW0mWj.png',
        buttonText: 'Eugheugh',
        link: 'ZbEzWyDd4vY'
    },
    {
        name: "Apple",
        image: 'https://i.imgur.com/f27D5IB.png',
        buttonText: 'Ok',
        link: 'I8IFyImOYE0'
    },
    {
        name: "Spooky",
        image: 'https://i.imgur.com/lgpVq8V.png',
        buttonText: 'wHaT iS iT?!?!',
        link: 'w4gtZ3UTdXQ'
    },
    {
        name: "Skittles and Hmphry",
        image: 'https://i.imgur.com/T439zz3.png',
        buttonText: 'This is Insta Pup!',
        link: 'COVoDq_HMwI'
    }
]

export default animalfwiendata;