import snuffles from '../images/snuffles.png';
import { Link } from 'react-router-dom';

const Home = () => {
    return (
        <section className="App moon-bg">
            <div className="container" style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
            <div >     
                <img src={snuffles} className="App-logo" alt="logo" />
            </div>
            <Link
                className="btn fade-in"
                to="/party"
            >
                Yis?
            </Link>
            </div>
        </section>
    )
}

export default Home;