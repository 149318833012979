import { useEffect } from 'react';
import JSConfetti from 'js-confetti';
import snufflesString from '../images/snuffles-string.png';

const jsConfetti = new JSConfetti();

const StringStash = () => {
  useEffect(() => {
    jsConfetti.addConfetti({
      emojis: ['🧵', '🎈', '🪀','➰'],
      confettiRadius: 2,
      confettiNumber: 40,
    })
    const stringInterval = setInterval(() => {
      jsConfetti.addConfetti({
        emojis: ['🧵','🧶','🎈','🪀','➰'],
        confettiRadius: 2,
        confettiNumber: 40,
      })
    }, 1000);

    return () => {
        clearInterval(stringInterval);
    }
  }, []);
    return (
        <section className="App moon-bg">
            <div className="container grid" style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
            <h1>Don't look for the string stash!</h1>
              <div >     
                <img src={snufflesString} className="App-logo string" alt="logo" />
              </div>
            </div>
      </section>        
    )
}

export default StringStash;