import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Home from './components/Home';
import PartyPage from './components/PartyPage';
import StringStash from './components/StringStash';

const message =  window.prompt('Where\'s the ______?!');

const App = () => {
  if (message === 'cheese') {
    return (
      <Router>
        <Switch>
          <Route path="/party">
            <PartyPage />
          </Route>
          <Route path="/string-stash">
            <StringStash />
          </Route>
          <Route path="/">
            <Home />
          </Route>
        </Switch>
      </Router>
    );
  }
  return (
    <section className="container" style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
      <h1>404, Take that back!!!</h1>
    </section>
  ) 
}

export default App;