import {useState} from 'react';
import cake from '../images/cake.png';
import ModalVideo from 'react-modal-video'

const AnimalCard = ({animal}) => {
    const [isOpen, setOpen] = useState(false);
    const [flipped, setFlipped] = useState(false);

    return (
        <>
            <ModalVideo channel='youtube' autoplay isOpen={isOpen} videoId={animal.link} onClose={() => setOpen(false)} />
            <article className={flipped ? 'animal-card flipped' : 'animal-card'} onClick={() => setFlipped(true)}>
                <div className="animal-card__inner">
                    <div className="animal-card__front">
                        <img src={cake} alt="birthday cake" />
                    </div>
                    <div className="animal-card__back">
                        <img src={animal.image} alt={animal.alt} />
                        <div className="animal-card__text">
                        <button className="btn btn--pink" onClick={()=> setOpen(true)}>{animal.buttonText}</button>
                        </div>
                    </div>
                </div>
            </article>
    </>
    )
}

export default AnimalCard;