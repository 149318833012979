import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import AnimalCard from './AnimalCard';
import animalfwiendata from '../animalfwienddata';
import JSConfetti from 'js-confetti';

const jsConfetti = new JSConfetti();



const PartyPage = () => {
  useEffect(() => {
    jsConfetti.addConfetti({
      emojis: ['🧀', '🌙', '🥮'],
      confettiRadius: 2,
      confettiNumber: 40,
    })
    const cheeseInterval = setInterval(() => {
      jsConfetti.addConfetti({
        emojis: ['🧀', '🌙', '🥮'],
        confettiRadius: 2,
        confettiNumber: 40,
      })
    }, 3000);

    return () => {
      clearInterval(cheeseInterval);
    }
  }, []);
    return (
      <>
        <section className="App moon-bg">
            <div className="container grid">
            <h1>Happy Birthday Elaine!</h1>
            {animalfwiendata.map(animal => (
                <AnimalCard animal={animal} />
            ))}
            </div>
      </section>           
            <Link to="/string-stash" className="string-link">
              !!!
            </Link>
      </>
    )
}

export default PartyPage;